<template>
  <b-container fluid>
      <iq-card>
         <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_iabm.invitation') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="name"
                  >
                    <template v-slot:label>
                      {{ $t('globalTrans.name')}}
                    </template>
                    <b-form-input
                        v-model="search.name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="mobile"
                  >
                    <template v-slot:label>
                      {{ $t('globalTrans.mobile')}}
                    </template>
                    <b-form-input
                        type="number"
                        id="mobile"
                        v-model="search.mobile"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="12" sm="12" class="text-right">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
              </b-row>
          </template>
      </iq-card>
      <b-row>
          <b-col md="12">
              <iq-card>
                  <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('elearning_iabm.invitation') }} {{ $t('globalTrans.list') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <b-button class="btn_add_new ml-3" @click="pdfExport1">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                    </b-button>
                    <router-link :to="{ path: '/training-e-learning-service/iabm/invitation/form?circular_memo_no=' + $route.query.circular_memo_no }" :class="'btn btn_add_new'">
                      <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                    </router-link>
                  </template>
                  <template v-slot:body>
                      <b-overlay :show="loadingState">
                          <b-row>
                              <b-col md="12" class="table-responsive">
                                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(trainer_name)="data">
                                        <span class="capitalize">
                                          {{ $i18n.locale === 'bn' ? data.item.name_bn : data.item.name }}
                                        </span>
                                      </template>
                                      <template v-slot:cell(designation)="data">
                                        <span v-if="data.item.designation_id && data.item.not_here_designation == 0 && data.item.profession_type == 1">
                                          {{ getDesignation(data.item.designation_id) }}
                                        </span>
                                        <span v-else>
                                          {{ ($i18n.locale=='bn') ? data.item.designation_bn : data.item.designation_en}}
                                        </span>
                                      </template>
                                      <template v-slot:cell(work_place)="data">
                                        <span v-if="data.item.profession_type == 1 && data.item.not_here_office == 0">{{ getOfficeName(data.item.professional_office_id) + ', ' + getOrgName(data.item.professional_org_id) }}</span>
                                        <span v-if="data.item.profession_type == 1 && data.item.not_here_office == 1">{{ ($i18n.locale=='bn') ? data.item.office_name_bn : data.item.office_name }}</span>
                                        <span v-if="data.item.profession_type != 1 && data.item.not_here_office == 1">{{ ($i18n.locale=='bn') ? data.item.office_name_bn : data.item.office_name }}</span>
                                      </template>
                                      <template v-slot:cell(mobile)="data">
                                        <span class="capitalize">
                                          {{ data.item.trainer.mobile |mobileNumber }}
                                        </span>
                                      </template>
                                      <template v-slot:cell(email)="data">
                                        {{ data.item.trainer.email }}
                                    </template>
                                      <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('elearning_config.pending')}}</span>
                                          <span class="badge badge-success" v-else-if="data.item.status == 2">{{$t('globalTrans.send')}}</span>
                                          <span class="badge badge-success" v-else-if="data.item.status == 3">{{$t('elearning_iabm.approve_trainer_invitation')}}</span>
                                          <span class="badge badge-success" v-else-if="data.item.status == 4">{{$t('elearning_iabm.cancel_trainer')}}</span>
                                          <span class="badge badge-success" v-else-if="data.item.status == 5">{{$t('elearning_iabm.assigned_trainer')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <a href="javascript:" v-if="data.item.status == 1" class="btn btn-warning btn-sm" title="Send Mail" @click="sendMail(data.item)">
                                            {{ $t('globalTrans.send_mail')}}
                                        </a>
                                        <!-- <a href="javascript:" v-if="data.item.status === 2 || data.item.status === 4" class="btn btn-warning btn-sm" title="Send Mail" @click="sendMail(data.item)"> -->
                                        <a href="javascript:" v-if="data.item.status !== 1" class="btn btn-warning btn-sm" title="Send Mail" @click="sendMail(data.item)">
                                            {{ $t('globalTrans.re_send_mail')}}
                                        </a>
                                        <a href="javascript:" class="btn btn-primary btn-sm mt-1" title="Approved" @click="apprvedData(data.item)">
                                            {{ $t('elearning_iabm.assign_trainer')}}
                                        </a>
                                        <router-link title="Edit" :to="`/training-e-learning-service/iabm/invitation/form?id=${data.item.id}`" class="btn_table_action table_action_edit"><i class="ri-ball-pen-fill m-0"></i></router-link>
                                        <router-link title="View" :to="`/training-e-learning-service/iabm/invitation/details?id=${data.item.id}`" class="btn_table_action table_action_view"><i class="fas fa-eye"></i></router-link>
                                      </template>
                                  </b-table>
                                  <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                      />
                              </b-col>
                          </b-row>
                      </b-overlay>
                  </template>
              </iq-card>
          </b-col>
      </b-row>
      <b-modal id="modal-4" size="lg" :title="$t('elearning_iabm.lesson_assign_circular_wise') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
          </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
      </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { invitationList, invitationApproved, invitationMail, invitationPdf } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'
import Details from './Details'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
      return {
        circularLoading: false,
        search: {
          mobile: '',
          name: '',
          batch_no: 0,
          circular_memo_no: 0
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        trainerNameList: [],
        trainerList: [],
        circularList: [],
        courseModuleList: []
      }
  },
  computed: {
    formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('elearning_tim.trainer_name'), class: 'text-center' },
            { label: this.$t('globalTrans.designation'), class: 'text-center' },
            { label: this.$t('elearning_tpm.work_place'), class: 'text-center' },
            { label: this.$t('globalTrans.mobile'), class: 'text-center' },
            { label: this.$t('globalTrans.email'), class: 'text-center' },
            { label: this.$t('elearning_iabm.office_memo'), class: 'text-center' },
            { label: this.$t('irri_pump_main.subject'), class: 'text-center' },
            { label: this.$t('globalTrans.status'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'index' },
              { key: 'trainer_name' },
              { key: 'designation' },
              { key: 'work_place' },
              { key: 'mobile' },
              { key: 'email' },
              { key: 'office_memo' },
              { key: 'subject' },
              { key: 'status' },
              { key: 'action' }
            ]
        } else {
            keys = [
              { key: 'index' },
              { key: 'trainer_name' },
              { key: 'designation' },
              { key: 'work_place' },
              { key: 'mobile' },
              { key: 'email' },
              { key: 'office_memo' },
              { key: 'subject' },
              { key: 'status' },
              { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
  },
  async created () {
    this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId
    })
    this.search.circular_memo_no = this.$route.query.circular_memo_no
    this.search.batch_no = this.$route.query.batch_no
    this.loadData()
  },
  mounted () {
      core.index()
  },
  watch: {
    // 'search.training_title_id': function (newValue) {
    //   this.courseModuleList = this.getCourseModuleList(newValue)
    // }
  },
  methods: {
    getDesignation (id) {
        const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
        if (this.currentLocale === 'bn') {
            return designation !== undefined ? designation.text_bn : ''
        } else {
            return designation !== undefined ? designation.text_en : ''
        }
    },
    getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.currentLocale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
    },
    getOrgName (id) {
        const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
        if (this.currentLocale === 'bn') {
            return trainingType !== undefined ? trainingType.abbreviation_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.abbreviation : ''
        }
    },
    sendMail (item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.send_mail_confirm'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusCustomMail(trainingElearningServiceBaseUrl, invitationMail, item)
        }
      })
    },
    changeStatusCustomMail (baseUrl, uri, item) {
      Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const data = {
        organizationProfileList: this.$store.state.commonObj.organizationProfileList,
        officeTypeList: this.$store.state.commonObj.officeTypeList,
        officeList: this.$store.state.commonObj.officeList,
        designationList: this.$store.state.commonObj.masterDesignationList,
        org_id: 12
        // org_id: this.$store.state.Auth.authUser.org_id
      }
      RestApi.postData(baseUrl, `${uri}/${item.id}`, data).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    apprvedData (item) {
      this.toggleStatusCustom2(trainingElearningServiceBaseUrl, invitationApproved, item)
    },
    toggleStatusCustom2 (baseUrl, uri, item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusCustom(baseUrl, uri, item)
        }
      })
    },
    changeStatusCustom (baseUrl, uri, item) {
      Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Data has already added.'
          })
        }
        Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    dataChange () {
        this.loadData()
    },
    async searchData () {
        this.loadData()
    },
    loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, invitationList, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    getCustomDataList (data) {
      const listData = data.map(item => {
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        const fiscalYearData = {}
        if (typeof fiscalYearObj !== 'undefined') {
          fiscalYearData.fiscal_year = fiscalYearObj.text_en
          fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          fiscalYearData.fiscal_year = ''
          fiscalYearData.fiscal_year_bn = ''
        }
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
        const orgNameData = {}
        if (typeof orgObj !== 'undefined') {
          orgNameData.org_name = orgObj.text_en
          orgNameData.org_name_bn = orgObj.text_bn
        } else {
          orgNameData.org_name = ''
          orgNameData.org_name_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const trainingTitleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          trainingTitleData.training_title = trainingTitleObj.text_en
          trainingTitleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          trainingTitleData.training_title = ''
          trainingTitleData.training_title_bn = ''
        }
        const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
        const OfficeTypeData = {}
        if (typeof OfficeTypeListObj !== 'undefined') {
          OfficeTypeData.office_type = OfficeTypeListObj.text_en
          OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
        } else {
          OfficeTypeData.office_type = ''
          OfficeTypeData.office_type_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        const trainingCategoryData = {}
        if (typeof trainingCategoryObj !== 'undefined') {
          trainingCategoryData.training_category = trainingCategoryObj.text_en
          trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          trainingCategoryData.training_category = ''
          trainingCategoryData.training_category_bn = ''
        }
        return Object.assign({}, item, fiscalYearData, orgNameData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData)
      })
      return listData
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    },
    async pdfExport1 () {
      const sendData = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.$route.query.circular_memo_no,
        batch_no: this.$route.query.batch_no
      }
      // const sendData = {
      //   circular_memo_no: this.$route.query.circular_memo_no,
      //   batch_no: this.$route.query.batch_no
      // }
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, circular_memo_no: this.$route.query.circular_memo_no, batch_no: this.$route.query.batch_no, org_id: this.search.org_id, role: this.$store.state.Auth.activeRoleId }, sendData)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, invitationPdf, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
